import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { getSolanaProvider, ProviderTypes } from "../services/solana";
import { DEGEN_COIN_OP_SIDE_BETS_GAME_ID, DEGEN_COIN_OP_INITIALIZER_ID, DEGEN_COIN_OP_AUTHORITY_ID, DEGEN_COIN_OP_COLD_HOUSE_ID } from '../utils/program-constants';
import {
  getDegenCoinFlipInsuranceRewardsAccount,
} from '../utils/accounts';

export const getInsuranceRewardsAccountBalance = async (wallet: any) => {
  const provider = getSolanaProvider(wallet, ProviderTypes.PRIMARY);
  const [_rewards_account_pda] = await getDegenCoinFlipInsuranceRewardsAccount(
    provider.wallet.publicKey, DEGEN_COIN_OP_SIDE_BETS_GAME_ID, DEGEN_COIN_OP_INITIALIZER_ID, DEGEN_COIN_OP_AUTHORITY_ID, DEGEN_COIN_OP_COLD_HOUSE_ID
  );
  const balance = await provider.connection.getBalance(_rewards_account_pda, "processed");
  return balance / LAMPORTS_PER_SOL;
}
