import { PublicKey } from "@solana/web3.js";

const AUTHORITY_ADDRESS = process.env.REACT_APP_AUTHORITY_ADDRESS ?? 'modn84SAs1ccUAmxtmRY85yPz44qixgGrUwi276WYy1';

export const HOUSE_TREASURY = 'house_treasury';
export const HOUSE_STATE = 'house_state';
export const DEGENERATE = 'degenerate';
export const REWARDS = 'rewards';
export const DEGEN_COIN_FLIP = 'degen_coin_flip';
// export const DEGEN_COIN_FLIP_PROGRAM_ID = new PublicKey(
//   '3r2St9LGBnXMxv2msAZ4eGAPFNHwpNMbudkdBxdZCCRL',
// );

// export const INITIALIZER_ID = new PublicKey(
//   'DAdbcE5SdqoQArf7V87pYR6jPNmTtEUbR2XeC1kAzZjh',
// );

// export const AUTHORITY_ID = new PublicKey(
//   'B3tvcC35ehGUtLhb68K7YXyfn5pY8YbLttV3tsabTKvs',
// );

export const FUNDS_ID = new PublicKey(
  `9ACFS2Ekes8y1tFPy2toMkdVCquJWMZ3XuESphppmf6E`
);

export const MEMO_ID = new PublicKey(
  "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
);

export const PREORDER_ID = new PublicKey(
  "819VSjbvCvp9YpnKJAe2NCGTzyFajf5fwhBm5uYvhfor"
);

export const DEGEN_COIN_FLIP_PROGRAM_ID = new PublicKey(
  'BmjJ85zsP2xHPesBKpmHYKt136gzeTtNbeVDcdfybHHT',
);

export const INITIALIZER_ID = new PublicKey(
  'h2oMkkgUF55mxMFeuUgVYwvEnpV5kRbvHVuDWMKDYFC',
);

export const AUTHORITY_ID = new PublicKey(
  AUTHORITY_ADDRESS,
);

export const COLD_HOUSE_ID = new PublicKey(
  'i821bbVqQguuDLQp72gNWd52KBXBcEAQc4sVtZxWk4n',
);

export const DEGEN_COIN_OP_PROGRAM_ID = new PublicKey(
  '2RoWfh3xNtwGGpoXmXAWbVgiqqYiNEb9QrPYshQjRDGu'
);

export const DEGEN_COIN_OP_SIDE_BETS_GAME_ID = new PublicKey(
  'DCFSBGZFygDwMMpyCP1BHbstiYwHF7yuQ8yLfxcqDe2Y'
);

export const DEGEN_COIN_OP_INITIALIZER_ID =
  process.env.REACT_APP_RPC_URL?.includes('dev')
    ? new PublicKey('2R9brs5HvsjVjpJVpHTqdLP2EGstUmf4d6NbyBRDTLM6')
    : new PublicKey('NkrouNBqCZSKAk3aoWKVan6R43y6jtZGbg8cFkCqWdr');

export const DEGEN_COIN_OP_AUTHORITY_ID =
  process.env.REACT_APP_RPC_URL?.includes('dev')
    ? new PublicKey('TdxGuafnAKi33sy3XdMBAJAoTLPkTPpy2GjmHhybx2e')
    : new PublicKey('neozx8dquYBK1vUpesgGMVL7QW4L8VujzQYLYmKCSb9');

export const DEGEN_COIN_OP_COLD_HOUSE_ID =
  process.env.REACT_APP_RPC_URL?.includes('dev')
    ? new PublicKey('JCPNzwzxDJwNWNFv9NH7YrQDXuRayk9FgsWw4WamkiNy')
    : new PublicKey('Nyko7FrxkHkY7BtfPzSV8TUE2wmyUTea9R5q2JxXRnV');
