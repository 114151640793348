import { Modal } from "react-bootstrap";
import { useContext } from "react";
import { SettingContext } from "../../contexts/setting.context";

export function SettingModal({ styleCss, profile, ...props }) {
  const { liveFeed, setLiveFeedMode } = useContext(SettingContext);

  const handleLiveFeedToggle = () => {
    setLiveFeedMode(!liveFeed);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="settings-modal"
      centered
      contentClassName={`settings-modal theme-${styleCss}`}
    >
      <Modal.Body className="p-0">
        <div className="settings-card">
          <div className="settings-header">
            <i className={`fa fa-cog icon-${styleCss}`}></i>
            <h5 className="settings-title">Settings</h5>
          </div>

          <div className="settings-section">
            <div className="setting-item">
              <div className="setting-item-content">
                <i className={`fa fa-timeline icon-${styleCss}`}></i>
                <div className="setting-item-text">
                  <span className="setting-item-title">Live Flips</span>
                  <span className="setting-item-description">
                    Show real-time flip updates
                  </span>
                </div>
              </div>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={liveFeed}
                  onClick={handleLiveFeedToggle}
                  onChange={(ev) => ev.preventDefault()}
                  aria-label="Toggle live feed"
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
