// import { clusterApiUrl } from "@solana/web3.js";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { editProfile } from "../../api/profiles.service";
// import { NftCard } from "../NftCard";
import { DateTime } from "luxon";
import { AuthorizationContext } from "../../contexts/auth.context";

const discordUrl =
  "https://discord.com/api/oauth2/authorize?client_id=958201393087381524&redirect_uri=https%3A%2F%2Fdegencoinflip.com%2Fdiscord%2Foauth&response_type=code&scope=identify%20guilds.members.read";

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_self", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

// const URL = process.env.REACT_APP_RPC_URL ?? clusterApiUrl("devnet");

export function ProfileModal({
  defaultProfilePicture,
  setProfile,
  styleCss,
  wallet,
  profile,
  ...props
}) {
  const { auth, signIn } = useContext(AuthorizationContext);
  // const PAGE_SIZE = 6;
  const [nickname, setNickname] = useState(profile?.nickname ?? "");
  const [profileImageUrl] = useState(
    profile?.profileImageUrl ?? defaultProfilePicture
  );
  const [privateMode, setPrivateMode] = useState(profile?.privateMode ?? false);
  // const [currentInventoryIndex, setCurrentInventoryIndex] = useState(PAGE_SIZE);
  const isDark = styleCss === "dark";

  const handleChangeNickname = (e) => {
    e.preventDefault(); // prevent the default action
    setNickname(e.target.value); // set name to e.target.value (event)
  };
  // const selectPfp = async (token) => {
  // 	const { image } = token?.data?.metadata;
  // 	setProfileImageUrl(image);
  // 	setShowCollection(false);
  // };

  const saveProfile = async () => {
    const updatedProfile = await editProfile(
      wallet?.publicKey?.toString(),
      profileImageUrl,
      nickname,
      privateMode === profile?.privateMode ? null : privateMode,
      auth?.idToken
    );
    setProfile(updatedProfile);
    props.onHide();
  };

  const onLinkDiscord = async () => {
    await signIn(wallet);
    openInNewTab(discordUrl);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="profile-modal"
      centered
      contentClassName={`profile-modal ${
        isDark ? "theme-dark" : "theme-light"
      }`}
    >
      <Modal.Body className="p-0">
        <div className="profile-card">
          <div className="profile-header">
            <div className="profile-title-group">
              <i className={`fas fa-user profile-icon`}></i>
              <h5 className="profile-title">User Profile</h5>
            </div>
            <button
              className="close-button"
              onClick={props.onHide}
              aria-label="Close modal"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <div className="profile-content">
            <div className="profile-picture-section">
              <div className="profile-picture-wrapper">
                <img
                  className="profile-picture"
                  src={profileImageUrl ?? defaultProfilePicture}
                  alt="Profile"
                />
              </div>
            </div>

            <div className="profile-actions">
              <button
                className="btn btn-discord"
                onClick={() => onLinkDiscord()}
              >
                <i className="fab fa-discord"></i>
                Link Discord
              </button>
            </div>

            <div className="profile-form">
              <div className="input-group">
                <span className="input-group-text">
                  <i className="fas fa-user-tag"></i>
                </span>
                <input
                  type="text"
                  value={nickname}
                  onChange={handleChangeNickname}
                  className="form-control"
                  placeholder="Nickname"
                  aria-label="Nickname"
                />
              </div>

              <div className="private-mode-toggle">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="privateModeToggle"
                    checked={privateMode}
                    onChange={() => setPrivateMode(!privateMode)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="privateModeToggle"
                  >
                    Private Mode
                    <i
                      className="fas fa-info-circle info-icon"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Private mode will hide your profile from the public."
                    ></i>
                  </label>
                </div>
              </div>

              <div className="profile-info">
                <small>
                  Degen since{" "}
                  {profile?.createdAt
                    ? DateTime.fromISO(profile?.createdAt).toFormat("MMM yyyy")
                    : "inception"}
                  .
                </small>
              </div>
            </div>
          </div>

          <div className="profile-footer">
            <button className="btn btn-save" onClick={saveProfile}>
              Save Changes
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
