import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { calculatePayout, customRound, numberWithCommas, parseValue } from "../side-bets/helpers";
import InsuranceProgressBar from "./InsuranceProgressBar";
import BetAmountButton from "../BetAmountButton";

const suffixes = [2, 3];
const prefixOptions = ["L", "W", "H", "T"];
const betAmounts = [0.01, 0.05, 0.1, 0.25, 1, 'CLEAR'];

const InsuranceSelectionMenu = ({
    choice,
    setChoice,
    whaleMode,
    amount,
    setAmount,
    onSubmit,
    toggleWhaleMode
}) => {
    const incrementChoice = (currentChoice) => {
        const prefix = currentChoice.charAt(0);
        const currentSuffix = parseInt(currentChoice.slice(1), 10);
        const currentIndex = suffixes.indexOf(currentSuffix);
        const nextIndex = (currentIndex + 1) % suffixes.length;
        return `${prefix}${suffixes[nextIndex]}`;
    };

    const togglePrefix = () => {
        const currentPrefix = choice.charAt(0);
        const currentIndex = prefixOptions.indexOf(currentPrefix);
        const nextIndex = (currentIndex + 1) % prefixOptions.length;
        const newPrefix = prefixOptions[nextIndex];
        return `${newPrefix}${parseInt(choice.slice(1), 10)}`;
    };

    const handleChoice = () => {
        setChoice(incrementChoice(choice));
    };

    const handlePrefixToggle = () => {
        setChoice(togglePrefix());
    };

    const renderTooltip = (props, text) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return (<>


        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={
                <Popover id="popover-trigger-hover-focus" title="Popover bottom">
                    <div className="card">
                        <div className="card-body">
                            <h6>
                                Insure Your Next Flips
                            </h6>
                            If you buy insurance for a {choice.includes("2") ? '2' : '3'} {choice.includes("L") ? 'loss' : (choice.includes("W") ? 'win' : choice.includes("H") ? "heads" : "tails")} streak, you will earn {choice.includes("2") ? '4' : '8'}x your insurance amount if you {choice.includes("L") ? 'lose' : (choice.includes("W") ? 'win' : choice.includes("H") ? 'hit heads' : 'hit tails')} the next {choice.includes("2") ? '2' : '3'} flips in a row.
                        </div>
                    </div>
                </Popover>}
        >
            <div className="w-100 d-flex justify-content-center">
                <h4 className="mt-2 mb-1 d-inline-flex align-items-center">
                    IF YOU&nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, "Click to toggle condition")}
                    >
                        <button
                            className="btn btn-dark btn-sm d-flex align-items-center"
                            onClick={handlePrefixToggle}
                        >
                            {choice.includes("L") ? 'LOSE'
                                : choice.includes("W") ? 'WIN'
                                    : choice.includes("H") ? 'HEADS'
                                        : 'TAILS'}
                            <i className="ms-2 fas fa-exchange-alt"></i>
                        </button>
                    </OverlayTrigger>
                    &nbsp;A&nbsp;
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, "Click to change streak")}
                    >
                        <button
                            className="btn btn-dark btn-sm d-flex align-items-center"
                            onClick={handleChoice}
                        >
                            {parseInt(choice?.slice(1), 10)}-STREAK
                            <i className="ms-2 fas fa-arrow-right"></i>
                        </button>
                    </OverlayTrigger>
                    <span className="ms-1 fas fa-circle fa-info-circle fa-2xs text-secondary"></span>
                </h4>
            </div>

        </OverlayTrigger>
        <div className="d-flex justify-content-center my-3">
            <InsuranceProgressBar
                choice={choice}
                mainGameHistory={[]}
                size='l'
                direction='horizontal'
                showWonCoins={true}
                onTapped={null} />
        </div>
        {
            amount !== 'CLEAR' ?
                <h3 className="text-success my-3 text-center">PAYS <u>{numberWithCommas(calculatePayout(parseValue(choice).number, amount))}</u> SOL</h3>
                :
                <h3 className="my-3 text-center">-</h3>
        }
        <NormalButtons price={amount} setPrice={setAmount} />
        <hr />
        {
            amount !== 'CLEAR' ?
                <>
                    <button className={`btn btn-lg btn-dark mb-1 w-100 font-buttons rounded-0 double-or-nothing-button letter-spacing-1 fs-6`} onClick={() => amount === 'CLEAR' ? null : onSubmit()}>
                        BUY INSURANCE FOR  {amount.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} SOL
                    </button>
                </> :
                <>

                    <button className={`btn btn-lg btn-dark mb-1 w-100 font-buttons rounded-0 double-or-nothing-button letter-spacing-1 fs-6`} onClick={() => amount === 'CLEAR' ? null : onSubmit()}>
                        SELECT AN AMOUNT TO INSURE
                    </button>
                </>
        }
    </>);
}

// const StreakButtons = ({ choice, setChoice }) => {
//     return (
//         <div className="row mb-1">
//             <div className="col-6">
//                 <ImageToggleButton
//                     name="L2"
//                     buttonImageSrc={Images.STREAK2}
//                     isSelected={choice === "L2"}
//                     onSelect={() => setChoice("L2")}
//                 />
//             </div>
//             <div className="col-6">
//                 <ImageToggleButton
//                     name="L3"
//                     buttonImageSrc={Images.FLIP3}
//                     isSelected={choice === "L3"}
//                     onSelect={() => setChoice("L3")}
//                 />
//             </div>
//         </div>)
// };

const ButtonsMenu = ({ price, setPrice }) => {
    const renderButton = (amount) => (
        <div className="col-4" key={amount}>
            <BetAmountButton
                amount={amount}
                onSelect={() => amount === 'CLEAR' ? setPrice('CLEAR') : setPrice(amount + price > 10 ? 10 : customRound(amount + (price > 0 ? price : 0)))}
            />
        </div>
    );

    return (
        <>
            <div className="row mb-1">
                {betAmounts.slice(0, 3).map(renderButton)}
            </div>
            <div className="row mb-1">
                {betAmounts.slice(3).map(renderButton)}
            </div>
        </>
    );
};

const NormalButtons = ({ price, setPrice }) => {
    return <ButtonsMenu price={price} setPrice={setPrice} />;
};


export default InsuranceSelectionMenu;