import { useState, useEffect } from "react";

const BASE_URL = `https://degen-lullaby.s3.us-east-2.amazonaws.com`;

const urls = [
  `0.wav`,
  `1.wav`,
  `2.wav`,
  `3.wav`,
  `4.wav`,
  `5.wav`,
  `6.wav`,
  `7.wav`,
  `8.mp3`,
  `9.wav`,
  `10.wav`,
  `11.wav`,
  `12.m4a`,
  `13.wav`,
  `1up.mp3`, // 14
  `bonus-1.mp3`, // 15 red coin 1
  `bonus-2.mp3`, // 16 red coin 2
  `bonus-3.mp3`, // 17 red coin 3
  `18.mp3`, // 18 star appears,
  `19.mp3`, // 19 starman chill,
  `streaks/streak_sfx_1.mp3`, // 20
  `streaks/streak_sfx_2.mp3`, // 21
  `streaks/streak_sfx_3.mp3`, // 22
  `streaks/streak_sfx_4.mp3`, // 23 X NO
  `streaks/streak_sfx_5.mp3`, // 24
  `streaks/streak_sfx_6.mp3`, // 25
  `streaks/streak_sfx_7.mp3`, // 26
  `streaks/streak_sfx_8.mp3`, // 27
  `streaks/streak_sfx_9.mp3`, // 28
  `streaks/streak_sfx_10.mp3`, // 29
  `streaks/streak_sfx_11.mp3`, // 30
  `streaks/streak_sfx_12.mp3`, // 31
  `streaks/streak_sfx_13.mp3`, // 32
  `streaks/streak_sfx_14.mp3`, // 33
  `streaks/streak_sfx_15.mp3`, // 34
  `streaks/streak_sfx_16.mp3`, // 35
  `streaks/streak_sfx_17.mp3`, // 36
  `streaks/streak_sfx_18.mp3`, // 37
  `streaks/streak_sfx_19.mp3`, // 38
  `streaks/streak_sfx_20.mp3`, // 39
  `streaks/streak_sfx_21.mp3`, // 40
  `streaks/streak_sfx_22.mp3`, // 41
  `streaks/streak_sfx_23.mp3`, // 42
  `streaks/streak_sfx_24.mp3`, // 43
  `streaks/streak_sfx_25.mp3`, // 44
  `streaks/streak_sfx_26.mp3`, // 45
  `streaks/Next/next_streak_sfx_1.mp3`, // 46
  `streaks/Next/next_streak_sfx_2.mp3`, // 47
  `streaks/Next/next_streak_sfx_3.mp3`, // 48
  `streaks/Next/next_streak_sfx_4.mp3`, // 49
  `streaks/Next/next_streak_sfx_5.mp3`, // 50 X NOT EXISTS
  `streaks/Next/next_streak_sfx_6.mp3`, // 51
  `streaks/Next/next_streak_sfx_7.mp3`, // 52
  `streaks/Next/next_streak_sfx_8.mp3`, // 53
  `streaks/Next/next_streak_sfx_9.mp3`, // 54
  `streaks/Next/next_streak_sfx_10.mp3`, // 55
  `streaks/Next/next_streak_sfx_11.mp3`, // 56
  `streaks/Next/next_streak_sfx_12.mp3`, // 57
  `streaks/Next/next_streak_sfx_13.mp3`, // 58
  `streaks/Next/next_streak_sfx_14.mp3`, // 59
  `streaks/Next/next_streak_sfx_15.mp3`, // 60
  `streaks/Next/next_streak_sfx_16.mp3`, // 61
  `streaks/Next/next_streak_sfx_17.mp3`, // 62 x NOT USED IN STREAKS
  `streaks/Next/next_streak_sfx_18.mp3`, // 63
  `streaks/Next/next_streak_sfx_19.mp3`, // 64
  `streaks/Next/next_streak_sfx_20.mp3`, // 65
  `streaks/Next/next_streak_sfx_21.mp3`, // 66 x OLD ORIGAMI TO DELETE
  `streaks/Next/next_streak_sfx_22.mp3`, // 67
  `streaks/Next/next_streak_sfx_23.mp3`, // 68,
  `streaks/Next/super-streak.mp3`, // 69,
  `sidebets/1dub.wav`,// 70,
  `sidebets/coin_series.wav`, // 71,
  `sidebets/coin_drop.wav`, // 72,
  `sidebets/death.wav`, // 73,
  `variations/10-win.wav`, // 74
  `variations/10-win-100p.wav`, // 75
  `variations/10-win-50p.wav`, // 76
  `variations/10-win-20p.wav`, // 77
  `variations/9-lose-big-streak.wav`, // 78
  `variations/10-win-5p.wav`, // 79
  `variations/10-win-10p.wav`, // 80
  `variations/10-win-15p.wav`, // 81
  `variations/9-lose-big-streak-50p.wav`, // 82
  `variations/9-lose-big-streak-100p.wav`, // 83
  `variations/9-lose-big-streak-5p.wav`, // 84
  `variations/4-win-streak-10p.wav`, // 85
  `variations/4-win-streak.wav`, // 86
  `variations/4-win-streak-5p.wav`, // 87
  `variations/3-win-streak-2-95p.wav`, // 88
  `variations/3-win-streak-2.wav`, // 89
  `variations/3-win-streak-2-69p.wav`, // 90
  `variations/3-win-streak-2-35p.wav`, // 91
  `variations/3-win-streak-2-50p.wav`, // 92
  `variations/2-win.wav`, // 93
  `variations/2-win-10p.wav`, // 94
  `variations/2-win-20p.wav`, // 95
  `variations/2-win-5p.wav`, // 96
  `variations/1-lose-streak.wav`, // 97
  `variations/1-lose-streak-20p.wav`, // 98
  `variations/1-lose-streak-15p.wav`, // 99
  `variations/0-lose.wav`, // 100
  `variations/0-lose-15p.wav`, // 101
  `variations/0-lose-10p.wav`, // 102
  `variations/0-lose-5p.wav`, // 103
  `variations/6streak-win.mp3`,      // 104
  `variations/6streak-win-95p.wav`,  // 105
  `variations/6streak-win-50p.wav`,  // 106
  `variations/6streak-win-20p.wav`,  // 107
  `variations/6streak-win-10p.wav`,  // 108
  `variations/6streak-win-5p.wav`,   // 109
];

// Add this new mapping object after the urls array
const audioGroups = {
  '0-lose': urls.filter(url => url.includes('0-lose')),
  '1-lose': urls.filter(url => url.includes('1-lose')),
  '2-win': urls.filter(url => url.includes('2-win')),
  '3-win': urls.filter(url => url.includes('3-win')),
  '4-win': urls.filter(url => url.includes('4-win')),
  '9-lose': urls.filter(url => url.includes('9-lose')),
  '10-coin': urls.filter(url => url.includes('10-win')),
  '6streak-win': urls.filter(url => url.includes('6streak-win')),
};

// Add this helper function
const getRandomVariation = (groupKey) => {
  const variations = audioGroups[groupKey];
  if (!variations || variations.length === 0) return null;
  console.log(variations);
  const randomIndex = Math.floor(Math.random() * variations.length);
  console.log(randomIndex);
  return urls.findIndex(url => url === variations[randomIndex]);
};

const audioSources =
  urls.map(file => {
    const url = `${BASE_URL}/${file}`;
    const audio = new Audio(url);
    audio.volume = 0.4;
    audio.oncanplaythrough = false;
    return {
      url,
      audio,
    }
  });

export const useMultiAudio = () => {
  const [sources] = useState(audioSources);
  const [players, setPlayers] = useState(
    urls.map(url => {
      return {
        url,
        playing: false,
      }
    }),
  )

  const toggle = (targetIndex) => {
    const newPlayers = [...players]
    const currentIndex = players.findIndex(p => p.playing === true)
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[currentIndex].playing = false
      newPlayers[targetIndex].playing = true
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false
    } else {
      newPlayers[targetIndex].playing = true
    }
    setPlayers(newPlayers)
  }

  const toggleBg = (targetIndex) => {
    const newPlayers = [...players]
    const currentIndex = players.findIndex(p => p.playing === true)
    if (currentIndex !== -1 && currentIndex !== targetIndex) {
      newPlayers[targetIndex].playing = true
    } else if (currentIndex !== -1) {
      newPlayers[targetIndex].playing = false
    } else {
      newPlayers[targetIndex].playing = true
    }
    setPlayers(newPlayers)
  }

  const toggleGroup = (groupKey) => {
    const targetIndex = getRandomVariation(groupKey);
    if (targetIndex === null) return;
    toggle(targetIndex);
  };

  useEffect(() => {
    sources.forEach((source, i) => {
      players[i].playing ? source.audio.play() : source.audio.pause()
    })
  }, [sources, players])

  useEffect(() => {
    sources.forEach((source, i) => {
      source.audio.addEventListener('ended', () => {
        const newPlayers = [...players]
        newPlayers[i].playing = false
        setPlayers(newPlayers)
      })
    })
    return () => {
      sources.forEach((source, i) => {
        source.audio.removeEventListener('ended', () => {
          const newPlayers = [...players]
          newPlayers[i].playing = false
          setPlayers(newPlayers)
        })
      })
    }
    // eslint-disable-next-line
  }, [])

  return [toggle, toggleBg, toggleGroup]
}
