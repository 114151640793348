import { useWallet } from "@solana/wallet-adapter-react";
import { constants } from "../../utils/constants";
import InsuranceProgressBar from "./InsuranceProgressBar";
import { ProgressBar } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getInsuranceRewardsAccountBalance } from "../../api-smart-contracts/dco";

const constantsMsgs = {
    PROCESSING_MSG: "SOLANA IS PROCESSING YOUR FLIP",
    RUGGED_MSG: "RUGGING YOU ON-CHAIN",
    DOUBLED_MSG: "DOUBLING YOUR SOL ON-CHAIN",
    STILL_WORKING_MSG: "SOLANA STILL WORKING"
};


const PleaseWaitButton = () => {
    return (
        <div className="text-center">
            <h6 className="mt-2 text-success fst-italic">
                PLEASE WAIT...
                <span className="dot dot1">.</span><span className="dot dot2">.</span><span className="dot dot3">.</span>
                <img src="https://i.imgur.com/1pmDwMC.gif" alt="pepe" className="img-fluid mb-1" style={{ maxWidth: '24px' }} />
            </h6>
            <img src={constants.BUTTONS.PleaseWait} alt="please wait..." width="100%" height="100%" />
            <ProgressBar animated className="rounded-0" now={100} max={100} min={0} />
        </div>
    );
}


const InsuranceResult = ({ isDarkMode, coinOp, claimReward }) => {
    const wallet = useWallet();
    const [rewardsBalance, setRewardsBalance] = useState(0);
    const [message, setMessage] = useState(constantsMsgs.DOUBLED_MSG);

    const refreshRewardsBalance = async () => {
        const rewardsBalance = await getInsuranceRewardsAccountBalance(wallet);
        console.log(rewardsBalance);
        setRewardsBalance(rewardsBalance);
    }

    useEffect(() => {
        const interval = setInterval(async () => {
            if (message === constantsMsgs.DOUBLED_MSG) {
                setMessage(constantsMsgs.STILL_WORKING_MSG);
            }
            else if (message === constantsMsgs.STILL_WORKING_MSG) {
                setMessage(constantsMsgs.PROCESSING_MSG)
            }
            else {
                setMessage(constantsMsgs.DOUBLED_MSG)
            }
        }, 5000) // Switch every 3 seconds
        return () => clearInterval(interval); // Clean up the interval
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Set up polling interval
        const intervalId = setInterval(async () => {
            await refreshRewardsBalance();
        }, 5000); // Check every second

        // Initial check
        refreshRewardsBalance();

        // Cleanup interval when component unmounts or wallet changes
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [wallet]);

    return (
        <>

            <img
                className="img-fluid"
                src={isDarkMode ? 'https://i.imgur.com/rM7gRLw.png' : `https://i.imgur.com/sXRhmwW.png`}
                alt="Insurance" />
            <h4 className="justify-content-center d-flex">CONGRATS!</h4>
            <div className="d-flex justify-content-center mb-3">
                <InsuranceProgressBar
                    choice={coinOp?.choice}
                    mainGameHistory={coinOp?.mainGameHistory}
                    size='l'
                    direction='horizontal'
                    onTapped={null}
                />
            </div>
            <h3 className="text-success d-flex justify-content-center mb-3">
                YOU WON {coinOp?.winAmount} SOL
            </h3>
            {
                rewardsBalance > 0 ?
                    <img onClick={() => claimReward()} className="cursor-pointer double-or-nothing-button" src={constants.BUTTONS.ClaimReward} alt="claim reward" width="100%" height="100%" />
                    :
                    <PleaseWaitButton />
            }
            {/* <Button className="w-100 rounded-pill color-gold" variant="dark" type="button" onClick={() => claimReward()}>CLAIM REWARD</Button> */}
        </>
    );
}
export default InsuranceResult;