import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getWeeklyVolume } from "../../api/analytics.service";
import { CHALLENGES } from "../../utils/constants";
import { FlipRowPlaceholder } from "../FlipRowPlaceholder";

const now = DateTime.utc();
const thisStartOfWeek = now.startOf("week");
const earliestDate = DateTime.fromISO("2023-03-06T00:00:00.000Z");

// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const EVENT_ENDTIME = DateTime.fromISO(`2023-03-20T19:00:00.000`, { zone: 'UTC' });

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const formatCash = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

const PlayerRow = ({ nickname, profileImageUrl, rank, solPoints }) => (
  <div className="ranking-row">
    <div className="player-info">
      <div className="rank-badge">
        {rank === 1 ? (
          <i className="fa-solid fa-trophy"></i>
        ) : (
          <span className="rank-number">#{rank}</span>
        )}
      </div>
      <div className="avatar">
        <img
          src={profileImageUrl ?? CHALLENGES[0].profileImageUrl}
          alt={nickname}
          onError={(e) => {
            if (e.target.src !== CHALLENGES[0].profileImageUrl) {
              e.target.onerror = null;
              e.target.src = CHALLENGES[0].profileImageUrl;
            }
          }}
        />
      </div>
      <span className="player-name">{nickname}</span>
    </div>
    {solPoints > 0 && (
      <div className="points">
        <span className="points-value">{formatCash(solPoints)}</span>
        <span className="points-label">pts</span>
      </div>
    )}
  </div>
);

export function VolumeRankingModal({
  walletId,
  styleCss,
  authToken,
  ...props
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startOfWeek, setStartOfWeek] = useState(thisStartOfWeek);
  const isDark = styleCss === "dark";

  useEffect(() => {
    fetchLeaderboard(startOfWeek.toFormat("yyyy-MM-dd"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOfWeek, authToken]);

  const fetchLeaderboard = async (date) => {
    setLoading(true);
    const list = await getWeeklyVolume(date, authToken);
    setData(list);
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="volume-ranking-modal"
      centered
      contentClassName={`ranking-modal ${
        isDark ? "theme-dark" : "theme-light"
      }`}
    >
      <Modal.Body className="p-0">
        <div className="ranking-card">
          <div className="ranking-header">
            <h5 className="ranking-title">
              <span className="title-icon">🏆</span>
              WEEKLY RANKING
              <span className="title-icon">🏆</span>
            </h5>
            <div className="date-navigation">
              {earliestDate < startOfWeek && (
                <button
                  className="nav-button"
                  onClick={() => setStartOfWeek((sw) => sw.minus({ weeks: 1 }))}
                >
                  <i className="fa-solid fa-chevron-left"></i>
                </button>
              )}
              <span className="date-range">
                {startOfWeek.toLocaleString(DateTime.DATE_FULL)} -{" "}
                {startOfWeek
                  .plus({ weeks: 1 })
                  .toLocaleString(DateTime.DATE_FULL)}
              </span>
              {thisStartOfWeek.toSeconds() !== startOfWeek.toSeconds() && (
                <button
                  className="nav-button"
                  onClick={() => setStartOfWeek((sw) => sw.plus({ weeks: 1 }))}
                >
                  <i className="fa-solid fa-chevron-right"></i>
                </button>
              )}
            </div>
          </div>

          <div className="ranking-content">
            {loading
              ? new Array(6)
                  .fill(null)
                  .map((_, i) => <FlipRowPlaceholder key={i} n={i} />)
              : data?.map((item, index) => <PlayerRow key={index} {...item} />)}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
