import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateUserAccessDetails } from "../../api/degen.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { UserAccessStatus } from "../../utils/constants";

const BABY_COIN = "https://i.imgur.com/FjUZXhp.png";
const constants = {
  MIN_AGE: 21,
  AGE_RESTRICTED: "AGE_RESTRICTED",
  REGION_RESTRICTED: "REGION_RESTRICTED",
};

const maxDob = DateTime.now().minus({ years: constants.MIN_AGE });
export function VerifyUserModal({ styleCss, ...props }) {
  const { auth, enableVerified } = useContext(AuthorizationContext);
  const [enabled, setEnabled] = useState(true);
  const [regionBlocked, setRegionBlocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dob, setDob] = useState(maxDob.toFormat("yyyy-MM-dd"));

  const handleChangeDob = (e) => {
    e.preventDefault(); // prevent the default action
    setDob(e.target.value); // set name to e.target.value (event)

    const bday = DateTime.fromFormat(e.target.value, "yyyy-MM-dd");
    if (bday < maxDob) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const { status, reason } = await updateUserAccessDetails(
      dob,
      auth?.idToken
    );

    if (status === UserAccessStatus.VERIFIED) {
      enableVerified();
      props.onClose();
    } else if (reason === constants.REGION_RESTRICTED) {
      setRegionBlocked(true);
    } else if (reason === constants.AGE_RESTRICTED) {
      setEnabled(false);
    }

    setLoading(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div
            className={"card-body p-4" + (styleCss === "dark" ? " pb-0" : "")}
          >
            <>
              <img
                className="image-s rounded-circle mb-3"
                src={BABY_COIN}
                alt={"dfc"}
              />
              <p className="mb-0">
                <label for="date" class="form-label font-weight-bold fs-5 mb-1">
                  Confirm your birthday.
                </label>
                {!enabled && (
                  <>
                    <p className="text-danger font-weight-bold mt-0 mb-2">
                      Must be 21 or older.
                    </p>
                  </>
                )}
                <input
                  type="date"
                  placeholder="Birthdate"
                  id="date"
                  className={"form-control " + (!enabled ? " is-invalid" : "")}
                  value={dob}
                  onChange={handleChangeDob}
                />
              </p>
              {regionBlocked && (
                <div className="text-danger">
                  <p>
                    Our games are not available to people or companies who are
                    residents of, or are located, incorporated in a blocked
                    country or a restricted territory.
                  </p>
                </div>
              )}
            </>
          </div>
          <div className="card-footer text-center">
            <button
              className="btn btn-block w-100 btn-lg my-2 rounded-sm btn-warning"
              disabled={!enabled || loading}
              onClick={onSubmit}
            >
              {!loading && <>CONFIRM</>}
              {loading && (
                <>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </>
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
