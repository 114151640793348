import { useWallet } from "@solana/wallet-adapter-react";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { queryCoinFlipsByWallet } from "../../api/queries.service";
import { AuthorizationContext } from "../../contexts/auth.context";
import { FlipRow } from "../FlipRow";
import { FlipRowPlaceholder } from "../FlipRowPlaceholder";

export function MyFlipsModal({ styleCss, ...props }) {
  const wallet = useWallet();
  const { auth } = useContext(AuthorizationContext);
  const [recentFlips, setRecentFlips] = useState([]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const fetch = async () => {
    const now = DateTime.utc();
    const startTime = now.minus({ days: 7 }).toISO();
    const endTime = now.toISO();
    const limit = 100;
    const flips = await queryCoinFlipsByWallet(
      wallet.publicKey.toString(),
      startTime,
      endTime,
      limit,
      auth?.idToken
    );
    setRecentFlips(flips);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="flips-history-modal"
      centered
      contentClassName={`flips-modal theme-${styleCss} ${styleCss}-color`}
    >
      <Modal.Body className={`p-0`}>
        <div className="flips-card">
          <div className="flips-header">
            <div className="flips-title-group">
              <i className={`fa fa-history icon-${styleCss}`}></i>
              <h5 className="flips-title">My Recent Flips</h5>
            </div>
            <button
              className="close-button"
              onClick={props.onHide}
              aria-label="Close modal"
            >
              <i className={`fa fa-times icon-${styleCss}`}></i>
            </button>
          </div>

          <div className="flips-content">
            <div className="time-divider mt-0">
              <span className="time-label">Last Week</span>
              <div className="divider-line"></div>
            </div>

            <div className="flips-list">
              {recentFlips?.slice(0, 12)?.map((flip) => (
                <FlipRow
                  flip={flip}
                  type=""
                  key={flip.id}
                  defaultProfilePicture="https://i.imgur.com/FjUZXhp.png"
                />
              ))}
              {!recentFlips?.length &&
                new Array(10)
                  .fill(null)
                  .map((_, i) => <FlipRowPlaceholder key={i} n={i} />)}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
