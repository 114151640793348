import { useContext, useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import InsuranceActive from "./InsuranceActive";
import InsuranceResult from "./InsuranceResult";
import InsuranceSelectionMenu from "./InsuranceSelectionMenu";

export function InsuranceModal({
  coinOp,
  loading,
  amount,
  selectedChoice,
  isActive,
  isPendingReward,
  setAmount,
  setChoice,
  onSubmit,
  claimReward,
  ...props
}) {
  const { style } = useContext(StyleThemeContext);
  const [whaleMode, setWhaleMode] = useState(false);

  const toggleWhaleMode = (ev) => {
    ev.preventDefault();
    setAmount(whaleMode ? 0.05 : 3);
    setWhaleMode(!whaleMode);
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={`${style.includes('dark') ? `dark` : `default-bg light`}`}
      centered
      fullscreen={true}
      animation={false}
    >
      <Modal.Body className={"play step1 py-5 px-0 d-flex"}>
        <div className="form-signin my-auto">
          {
            !loading && <>
              <div className="d-flex justify-content-end mb-2">
                <CloseButton variant={style.includes('dark') ? 'white' : 'dark'} onClick={props.onHide} />
              </div>
              {!isActive && !isPendingReward && <>
                <img
                  className="img-fluid"
                  src={style.includes('dark') ? 'https://i.imgur.com/rM7gRLw.png' : `https://i.imgur.com/sXRhmwW.png`}
                  alt="Insurance" />
                {/* {
                  !!selectedChoice && <div className="text-center">
                    Lose {selectedChoice === "L2" ? "two" : "three"} in row<br/>Pays <u>{selectedChoice === "L2" ? "3" : "7"} to 1</u> your insurance.
                  </div>
                } */}
                <InsuranceSelectionMenu
                  whaleMode={whaleMode}
                  toggleWhaleMode={toggleWhaleMode}
                  choice={selectedChoice}
                  amount={amount}
                  setChoice={setChoice}
                  setAmount={setAmount}
                  onSubmit={onSubmit}
                />
              </>
              }
              {
                isActive && <>
                  <InsuranceActive
                    isDarkMode={style.includes('dark')}
                    coinOp={coinOp}
                    onHide={props.onHide}
                  />
                </>
              }
              {
                isPendingReward && <>
                  <InsuranceResult
                    isDarkMode={style.includes('dark')}
                    coinOp={coinOp}
                    claimReward={claimReward}
                  />
                </>
              }
            </>
          }

          {loading && (
            <>
              <div className="d-flex justify-content-center">
                <span className="loader" />
              </div>
            </>
          )}

        </div>
      </Modal.Body>
    </Modal>
  );
}
